define('ember-cli-clock/services/clock', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    interval: 1000,

    time: null,
    date: _ember['default'].computed('time', function () {
      return new Date(this.get('time'));
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.start();
    },

    start: function start() {
      var _this = this;

      this.update();
      this.set('intervalId', window.setInterval(function () {
        return _this.update();
      }, this.get('interval')));
    },

    stop: function stop() {
      window.clearInterval(this.get('intervalId'));
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.stop();
    },

    onIntervalChange: _ember['default'].observer('interval', function () {
      this.stop();
      this.start();
    }),

    update: function update() {
      var _this2 = this;

      _ember['default'].run(function () {
        return _this2.set('time', Date.now());
      });
    }
  });
});