define('ember-pikaday/components/pikaday-input', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var isPresent = _ember['default'].isPresent;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'input',
    attributeBindings: ['readonly', 'disabled', 'placeholder', 'type', 'name', 'size', 'required'],
    type: 'text',

    firstRender: true,

    setupPikaday: _ember['default'].on('didRender', function () {
      if (this.get('firstRender')) {
        var that = this;
        var firstDay = this.get('firstDay');

        var options = {
          field: this.$()[0],
          onOpen: _ember['default'].run.bind(this, this.onPikadayOpen),
          onClose: _ember['default'].run.bind(this, this.onPikadayClose),
          onSelect: _ember['default'].run.bind(this, this.onPikadaySelect),
          onDraw: _ember['default'].run.bind(this, this.onPikadayRedraw),
          firstDay: typeof firstDay !== 'undefined' ? parseInt(firstDay, 10) : 1,
          format: this.get('format') || 'DD.MM.YYYY',
          yearRange: that.determineYearRange(),
          minDate: this.get('minDate') || null,
          maxDate: this.get('maxDate') || null,
          theme: this.get('theme') || null
        };
        if (isPresent(this.get('position'))) {
          options['position'] = this.get('position');
        }
        if (isPresent(this.get('reposition'))) {
          options['reposition'] = this.get('reposition');
        }

        if (this.get('i18n')) {
          options.i18n = this.get('i18n');
        }

        var pikaday = new Pikaday(options);

        this.set('pikaday', pikaday);
        this.setPikadayDate();

        this.addObserver('value', function () {
          that.setPikadayDate();
        });

        this.addObserver('minDate', function () {
          this.setMinDate();
        });

        this.addObserver('maxDate', function () {
          this.setMaxDate();
        });
        this.set('firstRender', false);
      }
    }),

    teardownPikaday: _ember['default'].on('willDestroyElement', function () {
      this.get('pikaday').destroy();
    }),

    setPikadayDate: function setPikadayDate() {
      this.get('pikaday').setDate(this.get('value'), true);
    },

    setMinDate: function setMinDate() {
      this.get('pikaday').setMinDate(this.get('minDate'));
    },

    setMaxDate: function setMaxDate() {
      this.get('pikaday').setMaxDate(this.get('maxDate'));
    },

    onPikadayOpen: _ember['default'].K,

    onPikadayClose: function onPikadayClose() {
      if (this.get('pikaday').getDate() === null || _ember['default'].isEmpty(this.$().val())) {
        this.set('value', null);
      }
    },

    onPikadaySelect: function onPikadaySelect() {
      this.userSelectedDate();
    },

    onPikadayRedraw: _ember['default'].K,

    userSelectedDate: function userSelectedDate() {
      var selectedDate = this.get('pikaday').getDate();

      if (this.get('useUTC')) {
        selectedDate = _moment['default'].utc([selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()]).toDate();
      }

      this.set('value', selectedDate);
    },

    determineYearRange: function determineYearRange() {
      var yearRange = this.get('yearRange');

      if (yearRange) {
        if (yearRange.indexOf(',') > -1) {
          var yearArray = yearRange.split(',');

          if (yearArray[1] === 'currentYear') {
            yearArray[1] = new Date().getFullYear();
          }

          return yearArray;
        } else {
          return yearRange;
        }
      } else {
        return 10;
      }
    },

    autoHideOnDisabled: _ember['default'].observer('disabled', 'pikaday', function () {
      if (this.get('disabled') && this.get('pikaday')) {
        this.get('pikaday').hide();
      }
    })
  });
});
/* globals Pikaday */