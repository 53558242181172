define('ember-collection/utils/style-generators', ['exports', 'ember-collection/utils/translate'], function (exports, _emberCollectionUtilsTranslate) {
  exports.formatPixelStyle = formatPixelStyle;
  exports.formatPercentageStyle = formatPercentageStyle;

  function formatPixelStyle(pos, width, height) {
    var css = 'position:absolute;top:0;left:0;';
    css += (0, _emberCollectionUtilsTranslate.translateCSS)(pos.x, pos.y);
    css += 'width:' + width + 'px;height:' + height + 'px;';
    return css;
  }

  function formatPercentageStyle(pos, width, height) {
    var css = 'position:absolute;top:0;left:0;';
    css += (0, _emberCollectionUtilsTranslate.translateCSS)(pos.x, pos.y);
    css += 'width:' + width + '%;height:' + height + 'px;';
    return css;
  }
});