define('ember-drag-drop/components/draggable-object-target', ['exports', 'ember', 'ember-drag-drop/mixins/droppable'], function (exports, _ember, _droppable) {
  'use strict';

  exports.__esModule = true;

  var _ember2 = _interopRequireDefault(_ember);

  var _droppable2 = _interopRequireDefault(_droppable);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = _ember2.default.Component.extend(_droppable2.default, {
    classNameBindings: ['overrideClass'],
    overrideClass: 'draggable-object-target',
    isOver: false,

    handlePayload: function (payload, event) {
      var obj = this.get('coordinator').getObject(payload, { target: this });
      this.sendAction('action', obj, { target: this, event: event });
    },

    handleDrop: function (event) {
      var dataTransfer = event.dataTransfer;
      var payload = dataTransfer.getData("Text");
      this.handlePayload(payload, event);
    },

    acceptDrop: function (event) {
      this.handleDrop(event);
      //Firefox is navigating to a url on drop sometimes, this prevents that from happening
      event.preventDefault();
    },
    handleDragOver: function (event) {
      if (!this.get('isOver')) {
        //only send once per hover event
        this.set('isOver', true);
        this.sendAction('dragOverAction', event);
      }
    },
    handleDragOut: function (event) {
      this.set('isOver', false);
      this.sendAction('dragOutAction', event);
    },

    click(e) {
      let onClick = this.get('onClick');
      if (onClick) {
        onClick(e.originalEvent);
      }
    },

    actions: {
      acceptForDrop: function () {
        var hashId = this.get('coordinator.clickedId');
        this.handlePayload(hashId);
      }
    }
  });
});