define('ember-collection/utils/style-properties', ['exports', 'ember'], function (exports, _ember) {
  exports.styleProperty = styleProperty;
  exports.cssProperty = cssProperty;
  var _Ember$String = _ember['default'].String;
  var camelize = _Ember$String.camelize;
  var capitalize = _Ember$String.capitalize;

  var stylePrefixes = ['Webkit', 'ms', 'Moz', 'O'];
  var cssPrefixes = ['-webkit-', '-ms-', '-moz-', '-o-'];

  var style = typeof document !== 'undefined' && document.documentElement && document.documentElement.style;

  function findProperty(property, css) {
    var prop = css ? camelize(property) : property;
    if (prop in style) {
      return property;
    }
    var capitalized = capitalize(prop);
    for (var i = 0; i < stylePrefixes.length; i++) {
      var prefixed = stylePrefixes[i] + capitalized;
      if (prefixed in style) {
        return css ? cssPrefixes[i] + property : prefixed;
      }
    }
  }

  function styleProperty(prop) {
    return findProperty(prop, false);
  }

  function cssProperty(cssProp) {
    return findProperty(cssProp, true);
  }
});