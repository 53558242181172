define('ember-collection/utils/identity', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = identity;

  function identity(item) {
    var key = undefined;
    var type = typeof item;

    if (type === 'string' || type === 'number') {
      key = item;
    } else {
      key = _ember['default'].guidFor(item);
    }

    return key;
  }
});