define('ember-drag-drop/components/object-bin', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.__esModule = true;

  var _ember2 = _interopRequireDefault(_ember);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  var removeOne = function (arr, obj) {
    var l = arr.get('length');
    arr.removeObject(obj);
    var l2 = arr.get('length');

    if (l - 1 !== l2) {
      throw "bad length " + l + " " + l2;
    }
  };

  exports.default = _ember2.default.Component.extend({
    model: _ember2.default.A(),
    classNames: ['draggable-object-bin'],

    manageList: true,

    objectMoved: function () {},

    actions: {
      handleObjectDropped: function (obj) {
        if (this.get('manageList')) {
          this.get("model").pushObject(obj);
        }

        this.trigger("objectDroppedInternal", obj);
        this.sendAction("objectDropped", { obj: obj, bin: this });
      },

      handleObjectDragged: function (obj) {
        if (this.get('manageList')) {
          removeOne(this.get('model'), obj);
        }
        this.trigger("objectDraggedInternal", obj);
        this.sendAction("objectDragged");
      }
    }
  });
});