define('ember-drag-drop/components/sortable-objects', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.__esModule = true;

  var _ember2 = _interopRequireDefault(_ember);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = _ember2.default.Component.extend({
    dragCoordinator: _ember2.default.inject.service(),
    tagName: 'div',
    overrideClass: 'sortable-objects',
    classNameBindings: ['overrideClass'],
    enableSort: true,
    useSwap: true,
    sortingScope: 'drag-objects',
    sortableObjectList: _ember2.default.A(),

    init() {
      this._super(...arguments);
      if (this.get('enableSort')) {
        this.get('dragCoordinator').pushSortComponent(this);
      }
    },

    willDestroyElement() {
      if (this.get('enableSort')) {
        this.get('dragCoordinator').removeSortComponent(this);
      }
    },

    dragStart: function () {
      if (!this.get('enableSort')) {
        return false;
      }
      this.set('dragCoordinator.sortComponentController', this);
    },

    dragOver: function () {
      //needed so drop event will fire
      return false;
    },

    drop: function (event) {
      if (this.get('enableSort')) {
        this.sendAction('sortEndAction', event);
      }
    }
  });
});