define('ember-power-select/components/power-select/power-select-group', ['exports', 'ember-component', 'ember-computed', 'ember-power-select/templates/components/power-select/power-select-group'], function (exports, _emberComponent, _emberComputed, _powerSelectGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberComponent.default.extend({
    layout: _powerSelectGroup.default,
    tagName: '',
    disabled: _emberComputed.default.reads('group.disabled'),
    groupName: _emberComputed.default.reads('group.groupName')
  });
});